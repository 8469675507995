<template>
  <admin-layout>
    <template v-slot:title v-if="content">
      <admin-title class="title">
        {{ contentTitle }}
        <Badge :color="status.color">
          {{ status.label }}
        </Badge>
      </admin-title>
      <div class="sales-count" v-if="$route.params.category === 'stickers'">
        (총 판매건수: {{ content.numberOfSales }}건, 총 다운로드 수
        {{ content.numberOfDownloads }}건)
      </div>
    </template>

    <template v-slot:body v-if="content">
      <div class="section-bar">
        <div class="section-title">
          <Badge :solid="true" :color="creatorGrade.color">
            {{ creatorGrade.label }}
          </Badge>
          <div class="title">
            {{ creator.nickname }} ({{ creator.username }})
          </div>
          <router-link
            :to="{
              name: 'SingleCreator',
              params: { creatorId: creator.creatorId },
            }"
          >
            크리에이터 상세정보
          </router-link>
        </div>
        <div class="section-actions">
          <Button
            v-if="status.label === '판매중' || status.label === '판매중지'"
            v-on:click="handleSaleStatusClick(status.label)"
            :color="'red'"
            :borderd="true"
            :disabled="
              status.label === '판매중' &&
              content.salesTypes != null &&
              !content.salesTypes.includes('SINGLE')
            "
          >
            <template v-if="status.label === '판매중'"> 판매 중지 </template>
            <template v-else> 판매 재개 </template>
          </Button>
          <Button
            v-if="status.label === '판매중' || status.label === '차단'"
            v-on:click="
              isContentOnSale ? (isSalesModalOpen = true) : startSale()
            "
            :color="isContentOnSale ? 'red' : 'blue'"
            :solid="isContentOnSale"
            :loading="isLoading"
          >
            <template v-if="isContentOnSale"> 차단 </template>
            <template v-else> 차단 해제 </template>
          </Button>
          <Button v-on:click="saveChanges" :isLoading="isLoading">
            변경 사항 저장
          </Button>
        </div>
      </div>

      <div class="cards" v-if="content">
        <div class="card-col">
          <card-main
            :content="content"
            :contentId="$route.params.contentId"
            :contentTitle="contentTitle"
            :contentKOTitle="contentKOTitle"
            :contentKODesc="contentKODesc"
            :contentENTitle="contentENTitle"
            :contentENDesc="contentENDesc"
            :canEdit="true"
            :stickerOptions="{
              hasDarkmode: true,
              hasCheckeredBG: true,
            }"
            v-on:setTextContent="setTextContent"
            v-on:pushTag="pushTag"
            v-on:removeTag="removeTag"
          />
          <card-datepicker
            v-if="$route.params.category === 'images'"
            :getPublishedAt="content.publishedAt"
            :getFinishedAt="content.expiredAt || null"
            ref="reservation"
          />
          <card-files
            v-if="$route.params.category === 'images'"
            :files="content.files"
            ref="files"
          />
        </div>
        <div class="card-col">
          <card-recommended-place
            v-if="$route.params.category === 'stickers'"
            :_recommendedUsablePlace="content.recommendedUsablePlace"
            :isDisabled="
              $route.params.category === 'stickers' &&
              !content?.salesTypes?.includes('SINGLE')
            "
            ref="places"
          />
          <card-quality :_grade="content.grade" ref="quality" />
          <card-admin-tags
            :tags="content.keywords"
            v-on:pushTag="pushAdminTag"
            v-on:removeTag="removeAdminTag"
          />
          <card-category
            :_categoryIds="content.categoryIds"
            :activeOption="true"
            ref="categories"
          />
          <card-manage-market
            :_blacklistMarkets="content.blacklistMarkets"
            :_whitelistMarkets="content.whitelistMarkets"
            :isDisabled="
              $route.params.category === 'stickers' &&
              !content?.salesTypes?.includes('SINGLE')
            "
            ref="markets"
          />
          <card-sales-markets
            v-if="
              ($route.params.category === 'stickers' ||
                $route.params.category === 'virtualAssets') &&
              content?.salesMarkets
            "
            :sales-markets="content?.salesMarkets"
          />
          <card-manage-price
            v-if="$route.params.category !== 'virtualAssets'"
            :isDisabled="
              $route.params.category === 'stickers' &&
              !content?.salesTypes?.includes('SINGLE')
            "
            :_isFree="content.isFree"
            ref="price"
          />
          <!-- 판매 시작일 예약 -->
          <card-publish-reservation
            :_publishedAt="content.publishedAt"
            :isDisabled="true"
          />
          <card-image-type
            :_imageType="content.imageType"
            ref="imageTypes"
            v-if="$route.params.category === 'images'"
          />
          <card-mood
            :_mood="content.mood"
            ref="moods"
            v-if="$route.params.category === 'audios'"
          />
        </div>
      </div>
      <!-- 판매 상태 변경 예약 -->
      <card-status-reservation v-if="isShowStatusReservationSection" />
      <content-review-log :contentId="$route.params.contentId" />
    </template>
  </admin-layout>

  <teleport to="body">
    <modal-sales-end
      v-if="isContentOnSale"
      v-on:close="isSalesModalOpen = false"
      v-on:confirm="blockSales"
      :isOpen="isContentOnSale && isSalesModalOpen"
      :isLoading="isLoading"
      :contentType="contentType"
      ref="blockSalesModal"
    />
  </teleport>
</template>

<script>
import SingleContentBase from "@/views/contents/main/SingleContentBase";

import SingleContentSalesEndModal from "@/views/contents/main/SingleContentSalesEndModal";

import SingleContentManageMarket from "@/views/contents/cards/SingleContentManageMarket";
import SingleContentSalesMarkets from "@/views/contents/cards/SingleContentSalesMarkets";
import SingleContentManagePrice from "@/views/contents/cards/SingleContentManagePrice";
import SingleContentCategory from "@/views/contents/cards/SingleContentCategory";
import SingleContentMood from "@/views/contents/cards/SingleContentMood";
import SingleContentImageType from "@/views/contents/cards/SingleContentImageType";
import SingleContentQuality from "@/views/contents/cards/SingleContentQuality";
import SingleContentAdminTags from "@/views/contents/cards/SingleContentAdminTags";
import SingleContentDatepicker from "@/views/contents/cards/SingleContentDatepicker";
import SingleContentFiles from "@/views/contents/cards/SingleContentFiles";
import SingleContentRecommendUsablePlace from "@/views/contents/cards/SingleContentRecommendedUsablePlace";
import SingleContentStatusReservation from "@/views/contents/cards/SingleContentStatusReservation";
import SingleContentPublishReservation from "@/views/contents/cards/SingleContentPublishReservation";
import SingleContentCard from "@/views/contents/main/SingleContentCard";

import { getContentTypeDisplayData } from "@/utils";
import { apiGet, apiPut } from "@/api/apiMethods";
import { Checkbox } from "@ogqcorp/v-ui";

export default {
  extends: SingleContentBase,
  data() {
    return {
      isSalesModalOpen: false,
      usablePlaceArr: [
        {
          value: "CHATTING",
          label: "채팅용",
        },
        {
          value: "POSTING",
          label: "포스팅용",
        },
        {
          value: "BROADCASTING",
          label: "방송용",
        },
      ],
    };
  },
  created() {
    this.loadSingleContentById();
  },
  computed: {
    isShowStatusReservationSection() {
      return (
        this.content.status === "SALE" &&
        (this.$route.params.category === "stickers"
          ? this.content?.salesTypes?.includes("SINGLE")
          : this.$route.params.category !== "stickers")
      );
    },
  },
  methods: {
    // Single Content
    async requestSingleContentById() {
      const API_SINGLE_CONTENT_URL = `/dam/v5/admin/${this.$route.params.category}/${this.$route.params.contentId}`;
      const API_TOTAL_STATS_URL = `/pmt/v5/admin/digital-assets/${this.$route.params.contentId}/total-stats`;
      const [singleContentResponse, totalStatsResponse] = await Promise.all([
        apiGet(API_SINGLE_CONTENT_URL),
        apiGet(API_TOTAL_STATS_URL),
      ]);
      return {
        ...singleContentResponse?.data?.[0],
        ...totalStatsResponse?.data,
      };
    },
    async loadSingleContentById() {
      this.setupRouterGuard();
      try {
        const content = await this.requestSingleContentById();
        this.content = content;
        this.creator = content.creator;
        this.syncContentTextContents();
        console.log("content", this.content);
      } catch (err) {
        this.content = false;
        this.creator = false;
        this.$router.replace({ name: "PageNotFound" });
      }
    },
    async startSale() {
      this.isLoading = true;
      try {
        const confirm = await this.$confirm({
          msg: "차단을 해제하시겠습니까?",
          confirmText: "차단해체",
        });
        if (confirm) {
          const API_START_SALE_URL = `/dam/v5/admin/${this.$route.params.category}/${this.$route.params.contentId}/unblock`;
          await apiPut(API_START_SALE_URL);
          await this.loadSingleContentById();
          this.$alert({
            msg: "차단 해제했습니다.",
          });
        }
      } catch {
        this.$error();
      } finally {
        this.isLoading = false;
      }
    },
    async handleSaleStatusClick(status) {
      const API_START_SALE_URL = `/dam/v5/admin/${
        this.$route.params.category
      }/${this.$route.params.contentId}/${
        status === "판매중" ? "stopSale" : "startSale"
      }`;
      const msg = `${
        status === "판매중" ? "판매 중지되었습니다." : "판매 재개되었습니다."
      }`;

      await apiPut(API_START_SALE_URL);
      await this.loadSingleContentById();
      this.$alert({
        msg,
      });
    },
    getImageUrl(type, content) {
      if (!content) return;

      switch (type) {
        case "STICKER":
          return content.mainImageUrl;
        case "STOCK_IMAGE":
          return content.imageUrl;
        case "COLORING_SHEET":
          return content.beforeImageUrl;
        case "AUDIO":
          return content.coverImageUrl;
      }
    },
    async blockSales() {
      if (!this.$refs.blockSalesModal.reason) {
        await this.$alert({
          msg: "사유를 선택해주세요.",
        });
        this.isLoading = false;
        return;
      }

      if (this.isLoading) return;

      this.isLoading = true;
      const reason = this.$refs.blockSalesModal.reason;
      const API_BLOCK_URL = `/dam/v5/admin/${this.$route.params.category}/${this.$route.params.contentId}/block`;

      try {
        const daName = this.contentTitle;
        const daType = this.getUppercaseType(this.contentType);
        const creatorId = this.creator.creatorId;
        const data = {
          reason,
          daName,
          daType,
          creatorId,
          imageUrl: this.getImageUrl(daType, this.content),
          language: "EN",
        };
        await apiPut(API_BLOCK_URL, data);
        await this.loadSingleContentById();
        await this.$alert({
          msg: "차단되었습니다.",
        });
      } catch (err) {
        console.error("block ", err);
        this.$error();
      } finally {
        this.isLoading = false;
        this.isSalesModalOpen = false;
      }
    },
    async saveChanges() {
      this.isLoading = true;
      const confirm = await this.$confirm({
        msg: "변경사항을 저장하시겠습니까?",
      });

      if (!confirm) {
        this.isLoading = false;
        return;
      }

      const data = {
        ...{
          categoryIds: this.content.categoryIds,
          textContents: this.content.textContents,
          tags: this.content.tags,
          keywords: this.content.keywords,
          isFree: this.content.isFree,
          grade: this.content.grade || "LOW",
          blacklistMarkets: this.content.blacklistMarkets,
          whitelistMarkets: this.content.whitelistMarkets,
          publishedAt: this.content.publishedAt || null,
          expiredAt: this.content.expiredAt || null,
          recommendedUsablePlace: this.$refs.places?.recommendedUsablePlace,
          ...(this.$route.params.category === "audios"
            ? { mood: this.content.mood }
            : null),
          ...(this.$route.params.category === "images"
            ? { imageType: this.content.imageType }
            : null),
        },
        ...{
          categoryIds: this.$refs.categories.categoryIds,
          isFree: this.$refs?.price?.isFree || false,
          blacklistMarkets: this.$refs.markets.blacklistMarkets,
          whitelistMarkets: this.$refs.markets.whitelistMarkets,
          grade: this.$refs.quality.grade,
          textContents: this.getEdittedTextContent(),
          files: this.$refs.files?.newFiles || [],
          publishedAt:
            this.$refs.reservation?.reserved &&
            this.$refs.reservation?.publishedAt
              ? this.$formatTime(this.$refs.reservation.publishedAt.getTime())
              : null,
          expiredAt:
            this.$refs.reservation?.reserved &&
            this.$refs.reservation?.finishedAt
              ? this.$formatTime(this.$refs.reservation.finishedAt.getTime())
              : null,
          mood: this.$refs.moods?.mood || null,
          imageType: this.$refs.imageTypes?.imageType || null,
        },
      };
      try {
        const API_SAVE_URL = `/dam/v5/admin/${this.$route.params.category}/${this.$route.params.contentId}`;
        await apiPut(API_SAVE_URL, data);
        await this.loadSingleContentById();
        await this.$alert({
          msg: "변경사항이 저장됐습니다.",
        });
      } catch {
        await this.$error();
      } finally {
        this.isLoading = false;
      }
    },
  },
  components: {
    // Cards
    "card-manage-market": SingleContentManageMarket,
    "card-sales-markets": SingleContentSalesMarkets,
    "card-manage-price": SingleContentManagePrice,
    "card-category": SingleContentCategory,
    "card-mood": SingleContentMood,
    "card-image-type": SingleContentImageType,
    "card-quality": SingleContentQuality,
    "card-admin-tags": SingleContentAdminTags,
    "card-datepicker": SingleContentDatepicker,
    "card-files": SingleContentFiles,
    "card-recommended-place": SingleContentRecommendUsablePlace,
    "card-status-reservation": SingleContentStatusReservation,
    "card-publish-reservation": SingleContentPublishReservation,
    card: SingleContentCard,
    Checkbox,
    // Modals
    "modal-sales-end": SingleContentSalesEndModal,
  },
};
</script>

<style lang="scss" scoped>
.title {
  margin: 10px 0 0;
}

.sales-count {
  margin: 16px 0 0;
  font-size: 16px;
  color: var(--mono-800);
}

.section-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 40px;
  &.big {
    margin: 20px 0 40px;
  }
  &.spacer {
    margin-top: 80px;
  }
}
.section-bar .section-title {
  display: flex;
  align-items: center;
}
.section-bar a {
  color: var(--mono-900);
  padding: 0 12px;
  border: 1px solid var(--mono-900);
  font-size: 12px;
  font-weight: 500;
  height: 24px;
  line-height: 22px;
  outline: 0;
  text-decoration: none;
}

.section-title .title {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  margin: 0 20px 0 4px;
  .big & {
    font-size: 24px;
    margin: 0 0 0;
    color: #333;
  }
}

.section-actions button {
  margin-left: 10px;
}

.cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.card {
  padding: 20px;
  background-color: white;
  // height: 100%;
  & + .card {
    margin-top: 20px;
  }
}

.card-control-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-control-head > .label {
  font-size: 16px;
  font-weight: 500;
  color: var(--mono-900);
}

.card-control-head + .card-control-body {
  margin: 16px 0 0;
}

.card-control-body > .list-row {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}
</style>
