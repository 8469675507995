<template>
  <Card>
    <div class="card-control card-control-c">
      <div class="card-control-head">
        <div class="label">판매 시작일</div>
        <div class="actions">
          <Toggle v-model="isPublishReserved" :disabled="isDisabled" />
        </div>
      </div>
      <br />
      <div class="card-control-head">
        <Datepicker
          v-model="publishReservedAt"
          placeholder="판매 시작일"
          :disabled="!isPublishReserved"
        />
      </div>
    </div>
  </Card>
</template>

<script setup>
import Card from "@/views/contents/main/SingleContentCard";
import { Datepicker, Toggle } from "@ogqcorp/v-ui";
import {
  ref,
  onMounted,
  defineProps,
  defineExpose,
  getCurrentInstance,
  computed,
  watch,
  watchEffect,
} from "vue";

const props = defineProps({
  _publishedAt: {
    type: Number,
    required: true,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
});

const {
  appContext: {
    app: {
      config: {
        globalProperties: { $formatDate, $formatTime, $alert },
      },
    },
  },
} = getCurrentInstance();

const publishReservedAt = ref(0);
const isPublishReserved = ref(false);

// watchEffect(() => {
//   console.log(1234, $formatTime(publishReservedAt.value));
// });

onMounted(() => {
  if (!props._publishedAt) return;
  publishReservedAt.value = new Date(props._publishedAt * 1000);
});

const inputDateValue = computed(() => {
  return publishReservedAt.value
    ? $formatDate(publishReservedAt.value, "YYYY-MM-DD hh:mm:ss")
    : "";
});

// watch(publishReservedAt, (publishReserveDate) => {}, {
//   immediate: true,
// });

watch(
  publishReservedAt,
  (publishDate) => {
    if (props.isDisabled) return;
    // isPublishReserved.value = !!publishDate.length;

    if (!publishDate || !isPublishReserved.value) return;

    if (isPublishReserved.value) {
      const now = new Date(); // 현재시점 (연월일 시분초 단위)
      const todayDateYMD = $formatDate(now, "YYYY-MM-DD"); // 연월일 단위
      const publishDateYMD = $formatDate(publishDate, "YYYY-MM-DD"); // 연월일 단위

      const isBeforeNow = publishDate < now;
      const isSameYMD = publishDateYMD === todayDateYMD;

      if (!isBeforeNow) return;

      // 판매 시작일이 현재 시점 이전인데, 연월일 까지는 동일하고 시간만 현재보다 이전일 때
      if (isSameYMD) {
        const publishDateTimeHHmm = Math.floor(publishDate.getTime() / 60000);
        const todayDateTimeHHmm = Math.floor(now.getTime() / 60000);

        const isPastTime = publishDateTimeHHmm < todayDateTimeHHmm;
        if (!isPastTime) return;

        $alert({
          msg: "당일 날짜는 현재 시각 이후만 선택 가능합니다",
        });
        publishReservedAt.value = now;
        return;
      }
      $alert({
        msg: "판매 시작일은 당일 이전의 일자로 선택 불가합니다. 날짜를 다시 설정해주세요",
      });
      publishReservedAt.value = null;
    }
  },
  {
    immediate: true,
  }
);

defineExpose({
  publishReservedAt,
  isPublishReserved,
});
</script>

<style lang="scss" scoped>
.card-control + .card-control {
  margin-top: 36px;
}

.card-control + .card-control-c {
  margin-top: 10px;
}

.card-control-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-control-head .actions {
  display: flex;
  align-items: center;
  & > div {
    margin-left: 10px;
  }
}

.card-control-head > .label {
  font-size: 20px;
  font-weight: 500;
  color: var(--mono-900);
}

.card-control-head + .card-control-body {
  margin: 16px 0 0;
}

.card-control-body .message {
  font-size: 14px;
  color: var(--mono-400);
}

.card-control:not(.card-control-c):last-child {
  margin-top: 70px;
}

:deep(.input-wrapper) {
  input[disabled] {
    cursor: not-allowed;
  }
}
</style>
