<template>
  <card>
    <div class="card-control-head">
      <div class="label">예약 업로드</div>
      <div class="actions">
        <Toggle v-model="reserved" />
      </div>
    </div>
    <br>
    <div class="card-control-head">
      <Datepicker
        v-model="publishedAt"
        placeholder="예약일"
      />
      ~
      <Datepicker
        v-model="finishedAt"
        placeholder="종료일"
      />
    </div>
  </card>
</template>

<script>
import SingleContentCard from "@/views/contents/main/SingleContentCard";
import {Toggle, Datepicker} from '@ogqcorp/v-ui';

export default {
  props: {
    getPublishedAt: {
      type: Number,
      default: 0
    },
    getFinishedAt: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      reserved: false,
      publishedAt: false,
      finishedAt: false
    }
  },
  created() {
    this.publishedAt = new Date(this.getPublishedAt * 1000);
    this.finishedAt = new Date(this.getFinishedAt * 1000);
    this.reserved = !!this.getPublishedAt;
  },
  components: {
    card: SingleContentCard,
    Toggle,
    Datepicker
  },
}
</script>

<style lang="scss" scoped>
.card-control-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-control-head .actions {
  display: flex;
  align-items: center;
  & > div {
    margin-left: 10px;
  }
}

.card-control-head > .label {
  font-size: 16px;
  font-weight: 500;
  color: var(--mono-900);
}

.datepicker-outer {
  width: 200px;
}
</style>
