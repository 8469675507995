<template>
  <form v-on:submit.prevent class="image-uploader" ref="form">
    <div class="file-name" v-if="registrationFile" v-on:click="deleteImage">
      {{registrationFile.file.name}}
    </div>
    <Button
      color="blue"
    >
      <input type="file" accept=".jpg, .jpeg, .png, .pdf" v-on:change="onChange" />
      사업자 등록증 업로드
    </Button>
  </form>
</template>


<script>
import ImageUploadBase from "@/components/uploader/ImageUploadBase";
import {Button} from "@ogqcorp/v-ui"
export default {
  extends: ImageUploadBase,
  props: [
    'registrationFile'
  ],
  methods: {
    
  },
  components: {
    Button,
  },
};
</script>

<style lang="scss" scoped>
form {
  display: flex;
  align-items: center;
}

input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
}

.input {
  flex: 1;
}

.file-name {
  cursor: pointer;
  margin-right: 30px;
  font-size: 14px;
  &:hover {
    text-decoration: underline;
  }
}
</style>