<template>
  <PagePanel
    title="판매자 유형"
    :titleOptions="{ fontWeight: 'bold' }"
    :isEvenPadding="true"
  >
    <table-v>
      <table-v-row>
        <template v-slot:th>판매자 유형</template>
        <template v-slot:td>
          <div class="form-registration">
            <dropdown v-model="sellerType" :list="sellerTypes" />
            <file-upload
              v-if="
                sellerType === 'COMPANY' || sellerType === 'PERSONAL_COMPANY'
              "
              :registrationFile="registrationFile"
              v-on:imageSelected="imageSelected"
              v-on:imageDeleted="imageDeleted"
              class="uploader"
            />
          </div>
        </template>
      </table-v-row>
    </table-v>
  </PagePanel>
</template>

<script>
import TableV from "@/components/table/TableV";
import TableVRow from "@/components/table/TableVRow";

import { PagePanel, Dropdown } from "@ogqcorp/v-ui";
import { sellerTypes } from "../../creatorUtils";

import SingleCreatorFileUploader from "./SingleCreatorFileUploader";

import { apiGet } from "@/api/apiMethods";

export default {
  data() {
    return {
      sellerTypes,
    };
  },
  props: ["creator", "registrationFile", "sellerInfo"],
  inheritAttrs: false,
  computed: {
    sellerType: {
      get() {
        return this.sellerInfo.sellerType;
      },
      set(value) {
        this.$emit("update:sellerType", value);
      },
    },
  },
  components: {
    PagePanel,
    "table-v": TableV,
    "table-v-row": TableVRow,
    Dropdown,
    "file-upload": SingleCreatorFileUploader,
  },
};
</script>

<style lang="scss" scoped>
.form-registration {
  display: flex;
  width: 100%;
}

.uploader {
  margin-left: auto;
}
</style>
